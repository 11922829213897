import { Flex, Button, CogIcon, useModal } from 'packages/uikit'
import SettingsModal from './SettingsModal'

type Props = {
  color?: string
  mr?: string
}

const GlobalSettings = ({ color, mr = '8px' }: Props) => {
  const [onPresentSettingsModal] = useModal(<SettingsModal />)

  return (
    <Flex>
      <Button height="25px" variant="icon" onClick={onPresentSettingsModal} mr={mr} id="open-settings-dialog-button">
        <CogIcon height={24} width={24} color={color || 'black'} />
      </Button>
    </Flex>
  )
}

export default GlobalSettings
