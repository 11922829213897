import styled from 'styled-components'
import {
  Text,
  Flex,
  Heading,
  IconButton,
  // ArrowBackIcon,
  NotificationDot,
  ChevronLeftIcon,
  Button,
  Box,
  CloseIcon,
  useMatchBreakpoints,
} from 'packages/uikit'
import { useExpertModeManager } from 'state/user/hooks'
import GlobalSettings from 'components/Menu/GlobalSettings'
import Link from 'next/link'
// import { isMobile } from 'react-device-detect'
import { useTranslation } from 'contexts/Localization'
// import Transactions from './Transactions'
import { useRouter } from 'next/router'
import QuestionHelper from '../QuestionHelper'

interface Props {
  title: string
  subtitle: string
  helper?: string | any
  backTo?: string
  noConfig?: boolean
}

const AppHeaderContainer = styled(Flex).attrs({ padding: ['10px', , , '16px 25px'] })`
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.xs} {
    flex-direction: unset;
  }
`

const StyledHeading = styled(Heading).attrs({ scale: 'lg' })`
  font-weight: 500;
  color: black;
  white-space: nowrap;
`

const StyledFlex = styled(Flex)``

const Dots = styled.span`
  width: clamp(16px, 4vw, 26px);
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 2px solid black;

  &:first-child {
    background-color: yellow;
  }

  &:nth-child(2) {
    background: green;
  }

  &:nth-child(3) {
    background: #3b9aff;
  }
`

const StyledButton = styled(Button)`
  border: 1px solid #e81899;
  text-align: center;
  color: #e81899;
  font-weight: bold;

  ${({ theme }) => theme.mediaQueries.xxs} {
    min-width: 85px;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 4px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    min-width: 174px;
    padding: 8px;
    border-radius: 8px;
  }
`

const AppHeader: React.FC<Props> = ({ title, subtitle, helper, backTo, noConfig = false }) => {
  const [expertMode] = useExpertModeManager()
  const { isMobile } = useMatchBreakpoints()
  const router = useRouter()
  const { t } = useTranslation()

  return (
    <AppHeaderContainer>
      <Flex width="100%" alignItems="center" justifyContent="space-between" mr={noConfig ? 0 : '0px'}>
        {/* <Flex alignItems="center" style={{ gap: '5px' }}>
          <Dots />
          <Dots />
          <Dots />
        </Flex> */}

        <StyledHeading as="h2">
          {isMobile && router.pathname === '/remove/[[...currency]]' ? 'Remove LP' : title}
        </StyledHeading>

        {/* {backTo ? (
          <Flex justifyContent="flex-end">
            <Flex>
              <Link passHref href={backTo}>
                <IconButton scale="sm" variant="text" as="a">
                  <CloseIcon width="24px" color="black" />
                </IconButton>
              </Link>
            </Flex>
          </Flex>
        ) : !noConfig ? (
          <Flex width={['fit-content', , , , '88px']} alignItems="center" justifyContent="flex-end">
            <Flex>
              <NotificationDot show={expertMode}>
                <GlobalSettings color="black" />
              </NotificationDot>
            </Flex>
          </Flex>
        ) : (
          <>
            <Flex alignItems="center" style={{ visibility: 'hidden' }}>
              <Dots />
              <Dots />
              <Dots />
            </Flex>
          </>
        )} */}

        {!noConfig ? (
          <Flex width="88px" alignItems="center" justifyContent="flex-end">
            <Flex>
              <NotificationDot show={expertMode}>
                <GlobalSettings color="black" />
              </NotificationDot>
            </Flex>
          </Flex>
        ) : null}
      </Flex>
    </AppHeaderContainer>
  )
}

export default AppHeader
