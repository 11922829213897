import styled from 'styled-components'
import { Card } from 'packages/uikit'

export const BodyWrapper = styled(Card)`
  border-radius: 8px;
  max-width: 774px;
  min-width: 375px;
  width: 100%;
  z-index: 1;
  background: rgba(255, 255, 255, 1);

  border-radius: 30px;
  box-shadow: 0px 0px 50px 0px rgba(244, 59, 58, 1);

  ${({ theme }) => theme.mediaQueries.xxl} {
    min-width: 420px;
  }
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
